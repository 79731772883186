import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserProfile from '../components/ProfilePage/UserProfile/UserProfile';
import SubscriptionOptions from '../components/ProfilePage/SubscriptionOptions/SubscriptionOptions';
import LoginMethods from '../components/ProfilePage/LoginMethods/LoginMethods';
import PromoCodeInput from '../components/ProfilePage/PromoCodeInput/PromoCodeInput';
import ActiveSubscriptions from '../components/ProfilePage/ActiveSubscriptions/ActiveSubscriptions';
import FamilySubscriptionManager from '../components/ProfilePage/FamilySubscriptionManager/FamilySubscriptionManager';
import Loader from '../components/Common/Loader/Loader';
import authApi from '../utils/authApi';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activePurchases, setActivePurchases] = useState([]); 
  const [premiumPromocode, setPremiumPromocode] = useState(null);
  const [premiumFeatures, setPremiumFeatures] = useState({});
  const [ranksData, setRanksData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      
      const userResponse = await authApi.get(`/api/v1/user/fetchAllData`);
      setUserData(userResponse.data);

      const purchasesResponse = await authApi.get('/api/v1/purchase/getActivePurchases');
      setActivePurchases(purchasesResponse.data.activePurchases || []); // Убедитесь, что массив не undefined

      setPremiumPromocode(userResponse.data.purchasing?.premium_promocode || null); // Проверяем на наличие данных
      
      const premiunFeaturesResponse = await authApi.get('/api/v1/purchase/hasPaidFeatures');
      if(premiunFeaturesResponse.status === 200)
        setPremiumFeatures(premiunFeaturesResponse.data);

      const ranksDataResponse = await authApi.get('/api/v1/rank/list');
      setRanksData(ranksDataResponse.data);

      setIsLoading(false); // Данные загружены, отключаем лоадер
    } catch (error) {
      console.error('Failed to fetch user data', error);
      setError('Failed to fetch user data. Please try again later.');
      setIsLoading(false); // Отключаем лоадер в случае ошибки
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (isLoading) {
    return <Loader />; // Показываем лоадер, пока данные загружаются
  }

  // Проверяем наличие подписки premium или активного промокода
  const hasOwnPremiumAccess = Array.isArray(activePurchases) && activePurchases.some(
    (purchase) => purchase.product.grantAccess === 'premium'
  ) || premiumPromocode;

  return (
    <div className="container">
      <UserProfile userData={userData} ranksData={ranksData} isPremium={premiumFeatures.premium}/>
      {hasOwnPremiumAccess ? (
        <ActiveSubscriptions activePurchases={activePurchases} premiumPromocode={premiumPromocode} />
      ) : !premiumFeatures.premium ? (     
        <SubscriptionOptions />
      ): null}

      <LoginMethods userData={userData}/>
      <PromoCodeInput onPromoCodeApplied={fetchUserData} />

      <FamilySubscriptionManager hasOwnPremiumAccess={hasOwnPremiumAccess} premiumFeatures={premiumFeatures} updatePage={fetchUserData}/> 
    </div>
  );
};

export default ProfilePage;
