import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css'; // Путь к CSS файлу

const Footer = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ru', name: 'Русский' },
    { code: 'ar', name: 'العربية' },
    { code: 'ch', name: '中文' },
    { code: 'de', name: 'Deutsch' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'it', name: 'Italiano' },
    { code: 'jp', name: '日本語' },
    { code: 'kr', name: '한국어' },
    { code: 'nl', name: 'Nederlands' },
    { code: 'pl', name: 'Polski' },
    { code: 'pt', name: 'Português' },
    { code: 'ro', name: 'Română' },
    { code: 'tr', name: 'Türkçe' }
  ];

  return (
    <footer className="footer">
      <select
        className="language-select"
        value={currentLanguage}
        onChange={changeLanguage}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
      <div className="footer-info">
        {/* Эта строка будет отображаться только если язык ru */}
        {currentLanguage === 'ru' && (
          <p>ИП Митрофанов Лев Владиславович, ИНН 532205661343</p>
        )}
        <p><a href="mailto:support@milevstudio.com">support@milevstudio.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
