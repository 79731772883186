import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../context/ModalContext'; 
import './PromoCodeInput.css';

const PromoCodeInput = ({ onPromoCodeApplied }) => { // Принимаем функцию в качестве пропса
    const { t } = useTranslation();
    const [promoCode, setPromoCode] = useState('');
    const { showModal, hideModal } = useModal(); 

    const handleInputChange = (e) => {
        setPromoCode(e.target.value);
    };

    const handleApplyPromoCode = async () => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/v1/promocode/activate`,
                { code: promoCode },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            let formattedDate = undefined;
            if (response.data.premium_promocode) {
                const expirationDate = new Date(Date.now() + response.data.premium_promocode);
                formattedDate = expirationDate.toLocaleDateString('ru-RU', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            }

            const content = (
                <div>
                    <div>
                        <p>{t('Promocode activated you received')}</p>
                        <h3>{t('Promocode activated you received Premium until', { date: formattedDate })}</h3>
                        {response.data.wallet.coins !== 0 && (<h3>Coins: {response.data.wallet.coins}</h3>)}
                        {response.data.wallet.hint1 !== 0 && (<h3>Hint1: {response.data.wallet.hint1}</h3>)}
                        {response.data.wallet.hint2 !== 0 && (<h3>Hint2: {response.data.wallet.hint2}</h3>)}
                        {response.data.wallet.hint3 !== 0 && (<h3>Hint3: {response.data.wallet.hint3}</h3>)}
                        {response.data.wallet.hint4 !== 0 && (<h3>Hint4: {response.data.wallet.hint4}</h3>)}
                        {response.data.wallet.levelPoints !== 0 && (<h3>Level Points: {response.data.wallet.levelPoints}</h3>)}
                    </div>
                </div>
            );

            showModal(t('Promocode activated title'), content, {
                onClose: () => {
                    hideModal(); // Закрываем модальное окно
                    onPromoCodeApplied(); // Вызываем функцию обновления данных
                }
            }, { closeButtonType: 'button' });
        } catch (error) {
            let errorMessage = t('Promocode activation error unknown');
            switch (error.response.status) {
                case 404:
                    errorMessage = t('Promocode activation error not found');
                    break;
                case 400:
                    errorMessage = t('Promocode activation error already used');
                case 402:
                    errorMessage = t('Promocode activation error you already used');
            }
            showModal(t('Promocode not activated title'), <p>{errorMessage}</p>, { closeButtonType: 'button' });
        }
    };

    return (
        <div className="profile-section">
            <h2 className='profile-section-title'>{t('Enter promo title')}</h2>
            <div className="promo-code-container">
                <input
                    type="text"
                    className="input1 promo-code-input"
                    value={promoCode}
                    onChange={handleInputChange}
                    placeholder={t('Enter promo placeholder')}
                />
                <button className="button1 promo-code-button" onClick={handleApplyPromoCode}>
                    {t('Enter promo button')}
                </button>
            </div>
        </div>
    );
};

export default PromoCodeInput;
