import React from 'react';
import './ActiveSubscriptions.css';
import appleLogo from '../../../assets/icons/apple-logo.png';
import googleLogo from '../../../assets/icons/google-logo.png';
import promocodeLogo from '../../../assets/icons/promocode-logo.png';
import cardLogo from '../../../assets/icons/card-logo.png';
import { useTranslation } from 'react-i18next';

const ActiveSubscriptions = ({ activePurchases, premiumPromocode }) => {
  const { t } = useTranslation();

  const purchases = activePurchases.map((purchase) => {
    const { product, validation } = purchase;

    let provider = '';
    let icon = null;
    let type = t('Active subscription forever');
    let title = '';

    switch (product.method) {
      case 'GooglePlay':
        provider = 'Google';
        icon = googleLogo;
        break;
      case 'AppStore':
        provider = 'Apple';
        icon = appleLogo;
        break;
      case 'CardRussia':
        provider = 'CardRussia';
        icon = cardLogo;
        break;
      default:
        provider = product.method;
        icon = null;
        break;
    }

    if (product.type === 'subscription') {
      if (product.productId.includes('mounthly') || product.productId.includes('month')) {
        type =  t('Active subscription per month');
      } else if (product.productId.includes('year')) {
        type = t('Active subscription per year');
      } else {
        type = t('Active subscription subscription');
      }
    }

    if (product.grantAccess === 'premium') {
      title = t('Active subscription subscription title', { type: type })
    } else if (product.grantAccess === 'delete-ads') {
      title = t('Active subscription no ads');
    } else {
      title = t('Active subscription subscription');
    }

    let price = product.type === 'subscription' && validation.data 
      ? `${validation.data.price} ${validation.data.currency}` 
      : t('Active subscription one-time purchase');

    return {
      id: product.purchaseId,
      provider,
      title,
      endDate: product.type === 'subscription' && validation.expireTime 
        ? new Date(validation.expireTime).toLocaleDateString('ru-RU') 
        : null,
      price,
      icon,
    };
  });

  if (premiumPromocode) {
    const expirationDate = new Date(Date.now() + premiumPromocode);
    purchases.push({
      id: 'promocode',
      provider: 'Promocode',
      title: t('Active subscription subscription title', { type: t('Active subscription promocode') }),
      endDate: expirationDate.toLocaleDateString('ru-RU'),
      price: null,
      icon: promocodeLogo,
    });
  }

  return (
    <div className="profile-section">
      <h2 className="profile-section-title">{t('Active subscription title')}</h2>
      <div className="active-subscriptions-container items-list">
        {purchases.length > 0 ? (
          purchases.map((subscription) => (
            <div key={subscription.id} className="subscription-item profile-section-white-box">
              {subscription.icon && (
                <img src={subscription.icon} alt={`${subscription.provider} Logo`} className="subscription-icon" />
              )}
              <div className="subscription-info">
                <div className="text-title">
                  {subscription.title}
                </div>
                {subscription.endDate && (
                  <div className="text-description">
                    {t('Active subscription active untill', { date: subscription.endDate })}
                  </div>
                )}
                <div className="text-description">
                  {subscription.price}
                </div>
              </div>
              {subscription.provider === 'CardRussia' && (<button className="button1">{t('Active subscription manage subscription')}</button>)}
            </div>
          ))
        ) : (
          <div className="subscription-item profile-section-white-box"><span className="text-regular">{t('Active subscription no subscriptions')}</span></div>
        )}
      </div>
    </div>
  );
};

export default ActiveSubscriptions;
