import React, { useState } from 'react';
import './LoginMethods.css';
import appleLogo from '../../../assets/icons/apple-logo.png'; 
import googleLogo from '../../../assets/icons/google-logo.png'; 
import emailLogo from '../../../assets/icons/email-logo.png'; 
import { useModal } from '../../../context/ModalContext'; // Импортируем контекст модального окна
import AuthMethods from '../../AuthMethods/AuthMethods'; // Импортируем компонент AuthMethods
import { useTranslation } from 'react-i18next';

// Функция для получения логотипа на основе имени провайдера
const getProviderLogo = (providerName) => {
    switch (providerName.toLowerCase()) {
        case 'apple':
            return appleLogo;
        case 'google':
            return googleLogo;
        case 'email':
            return emailLogo;
        default:
            return null; // Если логотипа нет, можно вернуть null или placeholder
    }
};

const getProviderFormatedName = (providerName) => {
    switch (providerName.toLowerCase()) {
        case 'apple':
            return 'Apple';
        case 'google':
            return 'Google';
        case 'email':
            return 'Email';
        default:
            return providerName;
    }
};

const LoginMethods = ({ userData }) => {
    const { t } = useTranslation();

    const { showModal } = useModal(); // Получаем функцию для отображения модального окна

    const loginMethods = userData.providers.map((provider, index) => ({
        id: index + 1, // Уникальный идентификатор для каждого элемента
        provider: provider.providerName,
        email: provider.userEmail || provider.userName, // Используем email, если он есть, иначе имя пользователя
        logo: getProviderLogo(provider.providerName), // Получаем логотип на основе имени провайдера
    }));

    const providersOnly = loginMethods.map(method => method.provider);


    const handleAddLoginMethod = () => {
        showModal(null, <AuthMethods providers={providersOnly} addToken={true} />);
    };

    return (
        <div className="profile-section">
            <h2 className="profile-section-title">{t('Login methods title')}</h2>
            <div className='login-methods-container'>
                <div className="login-methods-list items-list">
                    {loginMethods.length > 0 ? (
                        loginMethods.map((method) => (
                            <div key={method.id} className="profile-section-white-box login-method">
                                {method.logo && (
                                    <img src={method.logo} alt={`${method.provider} Logo`} className="login-method-logo" />
                                )}
                                <div className="login-method-info">
                                    <span className="text-title">{getProviderFormatedName(method.provider)}</span>
                                    <span className="text-description">{method.email}</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="profile-section-white-box login-method">
                            <div className="login-method-info">
                                <span className="text-regular">{t('Login methods no data')}</span>
                            </div>
                        </div>
                    )}
                </div>
                <button className="button1" onClick={handleAddLoginMethod}>{t('Login methods add method')}</button>
            </div>
        </div>
    );
};

export default LoginMethods;
