// src/components/SubscriptionOptions/SubscriptionOptions.js

import React, { useState } from 'react';
import './SubscriptionOptions.css';
import subscription_icon from '../../../assets/icons/subscription_icon.png';
import { useTranslation } from 'react-i18next';

const SubscriptionOptions = () => {
  const [selectedOption, setSelectedOption] = useState('monthly');
  const { t } = useTranslation();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <h2 className="profile-section-title">{t('SubscriptionOptions title')}</h2>
      <div className="profile-section subscription-container profile-section-white-box">
      <p className='subtitle'>{t('SubscriptionOptions available for russia')}</p>
      <p className='info'>{t('Access to premium subscription in the app')}</p>
      <div className="subscription-content">
          <div className="subscription-options">
            <div
              className={`subscription-option ${selectedOption === 'monthly' ? 'selected' : ''}`}
              onClick={() => handleOptionChange('monthly')}
            >
              <div className="custom-radio">
                <input
                  type="radio"
                  id="monthly"
                  name="subscription"
                  value="monthly"
                  checked={selectedOption === 'monthly'}
                  onChange={() => handleOptionChange('monthly')}
                />
                <span className="radio-checkmark"></span>
              </div>
              <label htmlFor="monthly">
                <span className="price">{t('SubscriptionOptions price per month', {price: '290 RUB'})}</span>
                <span className="description">{t('SubscriptionOptions monthly subscription')}</span>
              </label>
            </div>
            <div
              className={`subscription-option ${selectedOption === 'yearly' ? 'selected' : ''}`}
              onClick={() => handleOptionChange('yearly')}
            >
              <div className="custom-radio">
                <input
                  type="radio"
                  id="yearly"
                  name="subscription"
                  value="yearly"
                  checked={selectedOption === 'yearly'}
                  onChange={() => handleOptionChange('yearly')}
                />
                <span className="radio-checkmark"></span>
              </div>
              <label htmlFor="yearly">
                <span className="price">{t('SubscriptionOptions price per year', {price: '1490 RUB'})}</span>
                <span className="description">{t('SubscriptionOptions yearly subscription')}</span>
              </label>
            </div>

            <div
              className={`subscription-option ${selectedOption === 'lifetime' ? 'selected' : ''}`}
              onClick={() => handleOptionChange('lifetime')}
            >
              <div className="custom-radio">
                <input
                  type="radio"
                  id="lifetime"
                  name="subscription"
                  value="lifetime"
                  checked={selectedOption === 'lifetime'}
                  onChange={() => handleOptionChange('lifetime')}
                />
                <span className="radio-checkmark"></span>
              </div>
              <label htmlFor="lifetime">
                <span className="price">{t('SubscriptionOptions price forever', {price: '2190 RUB'})}</span>
                <span className="description">{t('SubscriptionOptions forever subscription')}</span>
              </label>
            </div>
          </div>
          <div className="subscription-image">
            <img src={subscription_icon} alt="Subscription Illustration" />
          </div>
        </div>
        <button className="button1 continue-button">{t('SubscriptionOptions continue button')}</button>
{        <p className="footer-text">Then 48.99 USD per year. Cancel anytime.</p>
}      </div>
    </div>
  );
};

export default SubscriptionOptions;
